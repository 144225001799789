import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AppNotifyService } from './services/app-notify.service';


/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifyService: AppNotifyService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        // retry(1),
        catchError(errorResponse => {
          if (errorResponse instanceof HttpErrorResponse) {
            switch (errorResponse.status) {
              case 401:
                const returnUrl = this.router.url;
                if (!returnUrl.includes('/authentication')) {
                  sessionStorage.setItem('returnUrl', returnUrl);
                }
                this.router.navigate(['/authentication']);
                break;
              case 500:
                this.notifyService.notify(
                  'Oeps, er ging iets mis',
                  errorResponse.error?.message,
                  'error'
                );
                return throwError(errorResponse);
              default:
                this.notifyService.notify(
                  'Oeps, er ging iets mis',
                  errorResponse.message,
                  'error'
                );
                return throwError(errorResponse);
            }
          }
        })
      );
  }

}
