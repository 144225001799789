import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AppNotifyService {
  constructor(private toastr: ToastrService) { }

  public notify(
    title: string,
    message: string,
    type: 'error' | 'warning' | 'success'
  ) {
    switch (type) {
      case 'error':
        this.toastr.error(message, title, {
          enableHtml: true,
          progressBar: false,
          titleClass: 'mat-typography',
          messageClass: 'mat-typography',
          disableTimeOut: true,
          tapToDismiss: true,
          closeButton: true,
        });
        break;
      case 'warning':
        this.toastr.warning(message, title, {
          enableHtml: true,
          progressBar: false,
          titleClass: 'mat-typography',
          messageClass: 'mat-typography',
          disableTimeOut: true,
          closeButton: true,
        });
        break;
      case 'success':
        this.toastr.success(message, title, {
          enableHtml: true,
          progressBar: true,
          titleClass: 'mat-typography',
          messageClass: 'mat-typography',
          timeOut: 3000
        });
        break;
    }
  }
}
